@font-face {
  font-family: 'Acumin Variable Concept';
  src: url('assets/fonts/AcuminVariableConcept.otf');
}

body {
  margin: 0;
  padding: 0;

  font-family: 'Acumin Variable Concept',
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #0f1419;
  color: #ffffff;

  font-variation-settings: 'wght' 450, 'wdth' 100;
}

h1, h2 {
  font-variation-settings: 'wght' 700, 'wdth' 100;
}

h1, h2, h3, h4 {
  margin: 0;
}

.gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.bold {
  font-variation-settings: 'wght' 700;
}

.fineprint {
  font-variation-settings: 'wght' 450, 'wdth' 85;
}

.hover-link svg circle,
.hover-link svg path {
  transition: fill .6s ease-in-out;
}

.hover-link:hover svg circle,
.hover-link:hover svg path {
  fill: #ff8264;
  transition: fill .1s ease-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body > #CybotCookiebotDialog {
  background-color: #273042;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyContentTitle {
  font-family: 'Acumin Variable Concept';
  font-variation-settings: 'wght' 700;
  font-size: 1.4em;
  color: #fff;
  letter-spacing: 0.06em;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText {
  font-family: 'Acumin Variable Concept';
  font-variation-settings: 'wght' 600;
  color: #ff8264;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogPoweredbyLink {
  display: none;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyContent {
  padding-left: 0;
}

body > #CybotCookiebotDialog .CybotCookiebotDialogBodyButton {
  font-family: 'Acumin Variable Concept';
  font-variation-settings: 'wght' 700;
  color: #273042;
  letter-spacing: 0.03em;
  line-height: 1.5em;
  background-color: #ff8264;
  border: none;
  padding: 0.4em 0.8em;
  border-radius: 9999px;
}

body > #CybotCookiebotDialog .CybotCookiebotDialogBodyButton:hover {
  color: #273042;
  background-color: #ffa264;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  color: #273042;
  background-color: #3a4861;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:hover {
  color: #273042;
  background-color: #ffa264;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtons {
  margin-left: 0 !important;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsTable {
  width: 632px;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogDetail {
  background: transparent;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonsSelectPane {
  color: #8e99af;
  border-color: #8e99af;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelDetailsWrapper {
  background-color: #8e99af;
  border-color: #8e99af;
}

body > #CybotCookiebotDialog #CybotCookiebotDialogBodyLevelDetailsButton {
  font-family: 'Acumin Variable Concept';
  font-variation-settings: 'wght' 700;
  color: #273042;
}

@media screen and (min-width: 870px) {
  body > #CybotCookiebotDialog {
    max-width: 850px;
    left: calc(50% - 425px);
    border-radius: 30px 30px 0 0;
    mask-image: paint(inverted-corners);
    -webkit-mask-image: paint(inverted-corners);
    --corner-radius: 30 30 -30 -30;
  }
}
